import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {AxiosResponse} from 'axios';

import {getCountriesInfo, getUserData as getUserDataReq} from '@/src/api/authService';
import {RootState} from '@/src/lib/store/store';
import {ProfileData, setProfileData} from '@/src/lib/store/stores/profile';
import {ConfigDataFromGet, SessionDataFromGet, UserData} from '@/src/types/Wizard/types';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API_BASE_URL,
  }),
  endpoints: (builder) => ({
    getConfig: builder.query<ConfigDataFromGet['Config'], void>({
      query: () => ({
        url: `${process.env.FRONTEND_API_BASE_URL}/bff/config`,
      }),
      keepUnusedDataFor: 60 * 60 * 24,
    }),
    getSession: builder.query<SessionDataFromGet['Session'], void>({
      query: () => ({
        url: `${process.env.FRONTEND_API_BASE_URL}/bff/session`,
        params: {data: 'Session'},
      }),
      keepUnusedDataFor: 60 * 60,
    }),
    getProfile: builder.query<ProfileData, {flow: string | null}>({
      query: ({flow}) => ({
        url: '/api/2/profilewizard',
        params: {flow},
      }),
      keepUnusedDataFor: 60 * 60,
      transformResponse: (response: {results: ProfileData}) => response.results,
      async onQueryStarted({}, {dispatch, getState, queryFulfilled}) {
        const state = getState() as RootState;
        const existingProfile = state.profile?.profileData;

        if (existingProfile && Object.keys(existingProfile).length > 0) {
          return;
        }

        try {
          const {data} = await queryFulfilled;
          dispatch(setProfileData(data));
        } catch (err) {
          console.error('Loading Profile error:', err);
        }
      },
    }),
    getCountryByIp: builder.query<{userCountryByIp: string}, void>({
      queryFn: async () => {
        try {
          const res = await getCountriesInfo();
          return {data: {userCountryByIp: res?.data?.countryByIp}};
        } catch (error) {
          return {error: {status: 'CUSTOM_ERROR', error: 'Failed to fetch country info'}};
        }
      },
      keepUnusedDataFor: Infinity,
    }),
    getUserData: builder.query<UserData, void>({
      queryFn: async () => {
        try {
          const res: AxiosResponse<UserData> = await getUserDataReq();
          const data = res.data;

          return {data};
        } catch (error) {
          return {error: {status: 'CUSTOM_ERROR', error: 'Failed to fetch user data'}};
        }
      },
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {useGetConfigQuery, useGetSessionQuery, useGetProfileQuery, useGetCountryByIpQuery, useGetUserDataQuery} =
  api;
