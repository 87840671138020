import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {SessionDataFromGet} from '@/src/types/Wizard/types';

interface SessionState {
  data: SessionDataFromGet['Session'] | null;
}

const initialState: SessionState = {
  data: null,
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSessionData(state, action: PayloadAction<SessionDataFromGet['Session'] | null>) {
      state.data = action.payload;
    },
  },
});

export const {setSessionData} = sessionSlice.actions;

export default sessionSlice.reducer;
