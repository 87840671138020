import queryString from 'query-string';

import core from './core';

export function switchBeneficiaryAccount(CSRFToken: string | undefined, uid: string) {
  return core.post(`api/2/account/switchBA`, queryString.stringify({CSRFToken, uid}));
}

export function getProfileInfo(flow: string | null) {
  let query = 'api/2/profilewizard';
  if (flow) {
    query += '?flow=' + flow;
  }
  return core.get(query);
}
