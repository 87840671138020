'use client';

import {createContext, Dispatch, FC, memo, ReactNode, SetStateAction, useContext, useMemo, useState} from 'react';

import useUserDataApi, {
  HandleLogin,
  HandleLogout,
  HandleSignUp,
  handleResetPassword as resetPassword,
} from '@/src/hooks/useUserDataApi';
import {useGetUserDataQuery} from '@/src/lib/store/stores/api';
import {AdminSubmarineData, UserData} from '@/src/types/Wizard/types';

interface IUserDataContext {
  userData?: UserData | null;
  createdUserId: string | null;
  isLoggedIn: boolean;
  adminSubmarine: AdminSubmarineData;
  updateUserData: () => void;
}

interface IUserAuthContext {
  handleLogIn: HandleLogin;
  handleSignUp: HandleSignUp;
  handleLogout: HandleLogout;
  handleResetPassword: resetPassword;
  needTwoFactorAuth: boolean;
  errorMessages: any;
  setErrorMessages: Dispatch<SetStateAction<Record<string, any>>>;
  isFetchingAuth: boolean;
}

const UserDataContext = createContext<IUserDataContext | undefined>(undefined);
const UserAuthContext = createContext<IUserAuthContext | undefined>(undefined);

interface Props {
  children: ReactNode;
}

const UserProvider: FC<Props> = memo(({children}) => {
  const [isAdminActionsOpen, setAdminActionsOpen] = useState(false);
  const openAdminActions = () => setAdminActionsOpen(true);
  const closeAdminActions = () => setAdminActionsOpen(false);
  const [subX, setSubmarineX] = useState(300);
  const [subY, setSubmarineY] = useState(600);

  const setSubLocation = (x: number, y: number) => {
    setSubmarineX(x);
    setSubmarineY(y);
  };

  const [dimensions, setDimensions] = useState({
    minWidth: '350px',
    minHeight: '450px',
  });

  const {data: userDataResponse} = useGetUserDataQuery();

  const userData = useMemo(() => userDataResponse, [userDataResponse]);
  const isLoggedIn = useMemo(() => !!userData, [userData]);

  const {
    createdUserId,
    handleLogIn,
    handleSignUp,
    handleLogout,
    handleResetPassword,
    needTwoFactorAuth,
    errorMessages,
    setErrorMessages,
    isFetchingAuth,
    updateUserData,
  } = useUserDataApi();

  const adminSubmarine = {
    isAdminActionsOpen,
    closeAdminActions,
    openAdminActions,
    subX,
    subY,
    setSubLocation,
    dimensions,
    setDimensions,
  };
  return (
    <UserDataContext.Provider
      value={{
        userData,
        createdUserId,
        isLoggedIn,
        adminSubmarine,
        updateUserData,
      }}
    >
      <UserAuthContext.Provider
        value={{
          handleLogIn,
          handleSignUp,
          handleLogout,
          handleResetPassword,
          needTwoFactorAuth,
          errorMessages,
          setErrorMessages,
          isFetchingAuth,
        }}
      >
        {children}
      </UserAuthContext.Provider>
    </UserDataContext.Provider>
  );
});

UserProvider.displayName = 'UserProvider';

function useUser() {
  const context = useContext(UserDataContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserDataProvider');
  }
  return context;
}

function useAuth() {
  const context = useContext(UserAuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within a UserAuthContext');
  }
  return context;
}

export {useAuth, UserProvider, useUser};
