import {UserData} from '@/src/types/Wizard/types';

import {GtmEventInterface, ITrackingState} from './tracking.types';

const useTrackingState = (userData?: UserData | null): ITrackingState => {
  const gtmTrack = (props: GtmEventInterface): void => {
    const dataLayer = (window as any).dataLayer || [];
    const vtc = document.cookie.match('_vtc=([^;]*)');

    const eventData = {
      user_id: userData?.id,
      vtc_key: vtc && vtc[1],
      ...props,
    };

    dataLayer.push(eventData);
  };

  return {
    gtmTrack,
  };
};

export default useTrackingState;
