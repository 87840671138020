import {useCallback, useEffect, useMemo, useState} from 'react';

import {usePathname, useRouter} from 'next/navigation';

import {loginUser, logout, notifyHubspotRegistration, postResetPassword, signupUser} from '@/src/api/authService';
import useTrackingState from '@/src/context/Tracking/useTrackingState';
import {useAppDispatch} from '@/src/lib/store/hooks';
import {useGetUserDataQuery} from '@/src/lib/store/stores/api';
import {setSessionData} from '@/src/lib/store/stores/session';
import {LoginRequestParams, PostSignupParams, USER_ROLES} from '@/src/types/Wizard/types';
import {GTMEvents} from '@/src/utils/globalEvents';

export type KeyStringItem = Record<string, string>;

export interface SignupUserProps {
  status: number;
  data: {
    errors: [];
    user_id: string;
    user_ip: string;
  };
}

export interface HandleLoginParams {
  username: string;
  password: string;
  twoFactorAuthCode?: string;
  recaptchaValue?: string | null;
  captchaService?: string | null;
}

export interface HandleSignUpParams {
  username: string;
  email: string;
  recaptchaValue: string | null | undefined;
  password: string;
  agreeTerms: boolean;
  userType?: string | null | undefined;
  userCountry?: string | null | undefined;
  companyName?: string | null | undefined;
  config: any;
  registrationPage: any;
  captchaService?: any;
}

export type HandleLogin = (params: HandleLoginParams, config: any) => void;
export type HandleSignUp = (params: HandleSignUpParams) => void;
export type HandleLogout = (CSRFToken: string) => void;
export type handleResetPassword = (email: string, CSRFToken: string) => Promise<any>;

const useUserDataApi = () => {
  const {gtmTrack} = useTrackingState(null);
  const [errorMessages, setErrorMessages] = useState<Record<string, any>>({});
  const [createdUserId, setCreatedUserId] = useState<string | null>(null);
  const [responseStatus, setResponseStatus] = useState('idle');
  const dispatch = useAppDispatch();
  const needTwoFactorAuth = responseStatus === 'need two factor auth';
  const {push} = useRouter();
  const pathname = usePathname();

  const {refetch: refetchUserData, data: userData} = useGetUserDataQuery();

  const isWizardEntry = useMemo(() => pathname?.includes('/wizard/2/entry'), []);

  const handleRedirect = useCallback(
    (userType: string) => {
      const entryValue = sessionStorage.getItem('entryValue');
      if (isWizardEntry && entryValue === 'windowShopping' && userType === 'customer') {
        return push('my-projects');
      }
    },
    [isWizardEntry]
  );

  const featureWizardEntry = useMemo(() => 'false', []);

  const handleErrorLogin = (err: any) => {
    setResponseStatus('idle');
    const message = err?.response?.data?.message;

    if (err?.response?.status === 403) {
      window?.resetFriendlyCaptcha();
    }

    if (err?.response?.status === 417) {
      setResponseStatus('need two factor auth');
    }

    if (typeof message !== 'string') {
      return;
    }
    if (message.startsWith('[username]')) {
      setErrorMessages({
        full_name: [message.replace('[username]', '')],
      });
      gtmTrack({
        event: GTMEvents.login_fail,
        fail_reason: 'Invalid username',
      });
    } else if (message.startsWith('[password]')) {
      gtmTrack({
        event: GTMEvents.login_fail,
        fail_reason: 'Invalid password',
      });

      setErrorMessages({
        pwd: [message.replace('[password]', '')],
      });
    } else if (message.startsWith('Captcha')) {
      gtmTrack({
        event: GTMEvents.login_fail,
        fail_reason: 'Captcha error',
      });
      setErrorMessages({
        'g-recaptcha_err': [message],
      });
    } else {
      gtmTrack({
        event: GTMEvents.login_fail,
        fail_reason: 'Something went wrong',
      });
      setErrorMessages({
        common: [message] || ['Something went wrong'],
      });
    }
  };

  const handleErrorSignup = (errors: any) => {
    setResponseStatus('idle');
    if (errors && typeof errors === 'object') {
      let errResult = {...errors};
      if (errors['g-recaptcha_err']) {
        gtmTrack({
          event: GTMEvents.signup_fail,
          fail_reason: 'Recaptcha error',
        });
        errResult = {
          ...errors,
          'g-recaptcha_err': [errors['g-recaptcha_err']],
        };
      }
      gtmTrack({
        event: GTMEvents.signup_fail,
        fail_reason: Object.values(errResult).join(', '),
      });
      setErrorMessages(errResult);
    }

    if (errors && errors['g-recaptcha_err']) {
      window?.resetFriendlyCaptcha();
    }
  };

  const handleLogIn: HandleLogin = (
    {username, password, twoFactorAuthCode, recaptchaValue, captchaService},
    config
  ) => {
    const params: LoginRequestParams = {
      username,
      password,
      'captcha-service': captchaService || '',
    };

    setResponseStatus('loading');
    if (needTwoFactorAuth) {
      params['2fa_code'] = twoFactorAuthCode;
    }

    if (errorMessages?.['g-recaptcha_err']?.[0] || recaptchaValue) {
      params.captcha = recaptchaValue;
    }

    loginUser(params, config)
      .then((responseData: any) => {
        setResponseStatus('idle');
        if (responseData.status === 200) {
          refetchUserData();
          setErrorMessages({});
        }
      })
      .catch(handleErrorLogin);
  };

  const handleSignUp: HandleSignUp = ({
    username,
    email,
    password,
    recaptchaValue,
    agreeTerms,
    userType,
    userCountry,
    companyName,
    config,
    registrationPage,
    captchaService,
  }) => {
    setResponseStatus('loading');
    const params: PostSignupParams = {
      full_name: username,
      user_email: email,
      pwd: password,
      action: 'reg',
      agree_terms: agreeTerms ? 'On' : '',
      user_type: userType || 'client',
      user_country: userCountry || '',
      company_name: companyName || '',
      'g-recaptcha-response': recaptchaValue,
      registrationPage,
      captcha_service: captchaService || '',
    };

    signupUser(params)
      .then(({status, data: {errors, user_id, user_ip}}: SignupUserProps) => {
        setResponseStatus('idle');
        if (status !== 200) {
          return;
        }

        if (errors) {
          handleErrorSignup(errors);
        } else if (user_id) {
          try {
            typeof window !== undefined && window.localStorage.setItem('newUser_noPayments', 'true');
          } catch (error) {
            console.error('failed to save newUser_noPayments to ls');
          }

          const userDataType = params.user_type === 'translator' ? 'translator' : 'client';

          gtmTrack({
            event: GTMEvents.registration,
            user_type: userDataType,
          });

          const eventData: KeyStringItem = {
            user_type: userDataType,
            user_id,
          };

          if (pathname && pathname?.indexOf('wizard') > -1) {
            eventData.wizard_version = featureWizardEntry;
          }

          gtmTrack({
            event: GTMEvents.signup_success,
            user_id,
            is_translator: userType === 'translator' ? 'yes' : 'no',
            ...eventData,
          });

          const capitalizeFirstLetter = (name: string) => name.charAt(0).toUpperCase() + name.slice(1);

          const fullName = username.replace(/\s+/g, ' ').trim();
          const splitName = fullName.split(' ');

          const firstName = capitalizeFirstLetter(splitName[0]);
          let lastName = '';
          for (let i = 1; i < splitName.length; i++) {
            const space = i < splitName.length - 1 ? ' ' : '';
            lastName += capitalizeFirstLetter(splitName[i]) + space;
          }

          const hubspotData = {
            firstname: firstName,
            lastname: lastName,
            email,
            company: companyName || '',
            business_personal__c: userDataType,
            oht_user_id__c: user_id,
            ip_address__c: user_ip || '',
          };

          if (params.user_type !== 'translator') {
            notifyHubspotRegistration(config, hubspotData);
          }

          setCreatedUserId(user_id);
          handleLogIn({username: email.trim(), password: password}, config);
          if (params.user_type === 'translator') {
            push('profile/?flow=1#languages');
          }

          handleRedirect(params.user_type);
        }
      })
      .catch((err: any) => {
        setResponseStatus('idle');
        console.log('err', err);
      });
  };

  const handleLogout = (CSRFToken: string) => {
    const formData = new FormData();
    formData.append('CSRFToken', CSRFToken);
    gtmTrack({event: GTMEvents.logout});
    logout(formData)
      .then((response) => {
        if (response?.status === 200) {
          setCreatedUserId(null);
          dispatch(setSessionData(null));
          refetchUserData();
        }
      })
      .catch((err) => console.log(err));
  };

  const handleResetPassword = async (email: string, CSRFToken: string) => {
    setResponseStatus('loading');

    const res = await postResetPassword(email, CSRFToken);
    setResponseStatus('idle');
    return res?.data;
  };

  const isFetchingAuth = responseStatus === 'loading';

  useEffect(() => {
    const {id, type} = userData || {};

    if (id && type) {
      handleRedirect(type.toLowerCase());

      const eventData = {
        user_id: id,
        is_translator: type === USER_ROLES.translator ? 'yes' : 'no',
      };

      gtmTrack({event: GTMEvents.login_success, ...eventData});
    }
  }, [userData]);

  return {
    createdUserId,
    needTwoFactorAuth,
    errorMessages,
    setErrorMessages,
    handleLogIn,
    handleSignUp,
    handleLogout,
    handleResetPassword,
    isFetchingAuth,
    updateUserData: refetchUserData,
  };
};

export default useUserDataApi;
